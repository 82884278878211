<template>
  <BaseElForm v-bind="$attrs">
    <BaseElFormItem
      v-for="info in displayData"
      :key="info.label"
      :label="info.label"
    >
      <p>{{ info.value }}</p>
    </BaseElFormItem>
  </BaseElForm>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'InfoList',
  props: { displayData: { type: Array, default: () => [] } },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>

::v-deep .el-form-item{
  @apply mb-0;
}
</style>
